import React, { useState } from "react";
import axios from "axios";
import "../views/signup.css";
import PersonIcon from "@mui/icons-material/Person";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
function Signup() {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const prof =
    "https://res.cloudinary.com/sabai/image/upload/f_auto,q_auto/mmd2c14kj1hr2wkguew3";
  const cov =
    "https://res.cloudinary.com/sabai/image/upload/f_auto,q_auto/c1bik1ixscz3g9o5apsx";
  const [password, setPassword] = useState("");
  const nav = useNavigate();

  const firebaseConfig = {
    apiKey: "AIzaSyA9gckdBvs0Q5Nh5WucqQjbz47oIWrqyRY",
    authDomain: "trussnetworks-670ab.firebaseapp.com",
    projectId: "trussnetworks-670ab",
    storageBucket: "trussnetworks-670ab.appspot.com",
    messagingSenderId: "225429067257",
    appId: "1:225429067257:web:7bf452be2833c36b2e9928",
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  function authenticate() {
    createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        const user = {
          username: fullname,
          email: email,
          password: password,
          profpic: prof,
          coverpic: cov,
        };

        const dat = {
          email: email,
          username: fullname,
        };

        axios
          .post(
            "https://server-101-jcvh.onrender.com/signup",
            { user },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then((res) => {
            nav("/profile", { state: dat });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        if (error.code === "auth/invalid-email") {
          alert("Invalid email");
        }
        if (error.code === "auth/weak-password") {
          alert("Password too weak");
        }
        if (error.code === "auth/email-already-in-use") {
          alert("Email already registered, use another email");
        }
      });
  }

  return (
    <div className="scont">
      <div className="scont1">
        <h1>Truss Networks</h1>
        <h3>Welcome to your</h3>
        <h3>professional community</h3>
      </div>
      <div className="scont2">
        <img
          src="./tlogo.png"
          alt="picha"
          height="40px"
          width="50px"
          style={{ marginTop: "10%", borderRadius: "4px" }}
        ></img>
        <h2>Hello! </h2>
        <h4>Sign Up to Get Started</h4>
        <div className="scont21">
          <PersonIcon
            style={{ marginBottom: "2%", color: "grey" }}
            fontSize="small"
          />
          <input
            id="fname"
            name="fname"
            type="text"
            value={fullname}
            placeholder="Full Name"
            onChange={(e) => {
              setFullname(e.target.value);
            }}
          ></input>
        </div>
        <div className="scont21">
          <MailOutlineIcon
            style={{ marginBottom: "2%", color: "grey" }}
            fontSize="small"
          />
          <input
            id="email"
            name="email"
            type="email"
            value={email}
            placeholder="Email Address"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></input>
        </div>
        <div className="scont21">
          <LockIcon
            style={{ marginBottom: "2%", color: "grey" }}
            fontSize="small"
          />
          <input
            id="password"
            name="password"
            type="password"
            value={password}
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          ></input>
        </div>

        <button
          type="submit"
          onClick={(e) => {
            authenticate();
          }}
        >
          Register
        </button>
        <label style={{ display: "block", marginTop: "1%" }}>
          Already have an account?
        </label>
        <button
          type="submit"
          style={{ backgroundColor: "green", marginTop: "2%" }}
          onClick={(e) => {
            nav("/login");
          }}
        >
          Login
        </button>
      </div>
    </div>
  );
}
export default Signup;
