import React, { useEffect, useState } from "react";
import "./gprofile.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Badge } from "@mui/material";
import { socket } from "./Pulse";

function Gprofile() {
  const nav = useNavigate();
  const location = useLocation();
  const [skills, setSkills] = useState([]);
  const [experience, setExperience] = useState([]);
  const [ntray, setNtray] = useState(0);
  const [mtray, setMtray] = useState(0);
  const [ptray, setPtray] = useState(false);
  const [proftray, setProftray] = useState(0);
  const [receiverabout, setReceiverabout] = useState();
  const [receiverprofession, setReceiverprofession] = useState();
  const [receivercoverpic, setReceivercoverpic] = useState();
  const email = location.state.email;
  const username = location.state.username;
  const about = location.state.about;
  const profession = location.state.profession;
  const profilepic = location.state.profilepic;
  const receiveremail = location.state.receiveremail;
  const receiverpic = location.state.receiverpic;
  const receiverusername = location.state.receiverusername;
  useEffect(() => {
    let email = receiveremail;
    axios
      .post(
         "https://server-101-jcvh.onrender.com/profile",
        { email },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setExperience(res.data.experience);
        setSkills(res.data.skills);
        setReceivercoverpic(res.data.coverpic);
        setReceiverabout(res.data.about);
        setReceiverprofession(res.data.profession);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    socket.emit("private message", email);
  }, [email]);
  useEffect(() => {
    socket.on("rece", (msg) => {
      setMtray(mtray + 1);
    });
  });
  useEffect(() => {
    socket.on("ntrayer", (msg) => {
      setNtray(ntray + 1);
    });
  });
  useEffect(() => {
    socket.on("pulseupdate", async (pulz) => {
      setPtray(true);
    });
  });
  useEffect(() => {
    socket.on("proftray", (msg) => {
      setProftray(proftray + 1);
    });
  });

  const profpic =
    "https://res.cloudinary.com/sabai/image/upload/v1715242363/deqjunruxn3rfgk4vq0v.png";

  return (
    <div className="gcont">
      <div className="prlcont1">
        <img
          src={profpic}
          height="100%"
          width="4%"
          style={{ borderRadius: "4px", display: "inline-flex" }}
        ></img>
        <div className="prlcont11">
          <div
            className="prlcont12"
            style={{ borderTop: "4px orangered solid", paddingTop: "1%" }}
          >
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                };
                nav("/pulse", { state: dat });
              }}
            >
              <Badge color="success" variant={ptray ? "dot" : "standard"}>
                <DynamicFeedIcon style={{ color: "orangered" }} />
              </Badge>
            </a>
          </div>

          <div className="prlcont12">
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                };

                nav("/messages", { state: dat });
              }}
            >
              <Badge badgeContent={mtray} max={2} color="secondary">
                <MessageIcon />
              </Badge>
            </a>
          </div>

          <div className="prlcont12">
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                };
                nav("/notification", { state: dat });
              }}
            >
              <Badge badgeContent={ntray} max={2} color="error">
                <NotificationsActiveIcon />
              </Badge>
            </a>
          </div>
        </div>
      </div>
      <div className="gcont1">
        <img
          src={receivercoverpic}
          height="300px"
          width="100%"
          style={{
            display: "inline-flex",
            margin: "1%",
            objectFit: "fill",
          }}
        ></img>
        <img
          src={receiverpic}
          height="100px"
          width="100px"
          style={{
            borderRadius: "50%",
            display: "inline-flex",
            marginLeft: "6%",
            marginTop: "-3%",
            objectFit: "contain",
          }}
        ></img>
      </div>
      <div className="gcont2">
        <h4>{receiverusername}</h4>
        <h5>{receiverprofession}</h5>
        <h6>{receiverabout}</h6>
        <button
          onClick={() => {
            const yuy = {
              email: email,
              profession: profession,
              username: username,
              profilepic: profilepic,
              about: about,
              receiverabout: receiverabout,
              receiverprofession: receiverprofession,
              receiveremail: receiveremail,
              receiverpic: receiverpic,
              receivercoverpic: receivercoverpic,
              receiverusername: receiverusername,
            };
            nav("/messages", { state: yuy });
          }}
        >
          Message
        </button>
      </div>
      <div className="gcont3">
        <h4>Work Experience</h4>
        {experience &&
          experience.map((data, index) => {
            return (
              <div className="gcont5p" key={index}>
                <div>
                  <div className="gcont51">
                    <h4>{data.company}</h4>
                    <h4>{data.role}</h4>
                    <h6>{data.start + " " + "--" + " " + data.end}</h6>
                    <h5>{data.roledesc}</h5>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="gcont4">
        <h4>Skills</h4>
      </div>
    </div>
  );
}
export default Gprofile;
