import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./views/Login";
import Signup from "./views/Signup";
import Messages from "./views/Messages";
import Pulse from "./views/Pulse";
import Notification from "./views/Notification";
import Profile from "./views/Profile";
import Gprofile from "./views/Gprofile";
import Landingpage from "./views/Landingpage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landingpage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/gprofile" element={<Gprofile />} />
        <Route path="/notification" element={<Notification />}></Route>
        <Route path="/pulse" element={<Pulse />}></Route>
        <Route path="/messages" element={<Messages />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
