import React, { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";

import Upload from "./Upload";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ShareIcon from "@mui/icons-material/Share";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DownloadIcon from "@mui/icons-material/Download";
import { saveAs } from "file-saver";
import {
  EmailShareButton,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  GabIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
  XIcon,
} from "react-share";

import "../views/pulse.css";
import axios from "axios";
import Comment from "./Comment";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Badge } from "@mui/material";
export const socket = io("https://server-101-jcvh.onrender.com");

function Pulse() {
  const [profilepic, setProfilepic] = useState();
  const [coverpic, setCoverpic] = useState();
  const [email, setEmail] = useState();
  const nav = useNavigate();
  const location = useLocation();
  const [about, setAbout] = useState();
  const [profession, setProfession] = useState();
  const [username, setUsername] = useState();

  useEffect(() => {
    if (typeof location.state === "string") {
      const mm = location.state;
      axios
        .post(
          "https://server-101-jcvh.onrender.com/login",
          { mm },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          setEmail(location.state);

          setUsername(res.data.username);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setEmail(location.state.email);

      setUsername(location.state.username);
    }
  }, [location.state]);
  useEffect(() => {
    if (email) {
      axios
        .post(
          "https://server-101-jcvh.onrender.com/profile",
          { email },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          setCoverpic(res.data.coverpic);
          setProfilepic(res.data.profpic);
          setAbout(res.data.about);
          setProfession(res.data.profession);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [email]);
  const videoRef = useRef(null);

  const [playing, setPlaying] = useState(false);
  const profpic =
    "https://res.cloudinary.com/sabai/image/upload/v1715242363/deqjunruxn3rfgk4vq0v.png";

  const [feed, setFeed] = useState([]);
  const [messages, setMessages] = useState([]);
  const [myemail, setMymail] = useState("john@gmail.com");

  const [dizplay, setDizplay] = useState("");
  const [tre, setTre] = useState("");
  const [clicked, setClicked] = useState(null);
  const [handleFocus, setHandleFocus] = useState(false);

  const [ntray, setNtray] = useState(0);
  const [mtray, setMtray] = useState(0);
  const [ptray, setPtray] = useState(false);
  const [proftray, setProftray] = useState(0);
  const locale = "en";
  const today = new Date();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const curMonth = monthNames[today.getMonth()];
  const time = today.toLocaleTimeString(locale, {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
  });
  const formattedDate = `${curMonth} ${today.getDate()}, ${time}`;

  useEffect(() => {
    if (typeof location.state === "string") {
      const mm = location.state;
      axios
        .post(
          "https://server-101-jcvh.onrender.com/login",
          { mm },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          setEmail(location.state);
          setAbout(res.data.about);
          setUsername(res.data.username);
          setProfession(res.data.profession);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setEmail(location.state.email);
      setAbout(location.state.about);
      setUsername(location.state.username);
      setProfession(location.state.profession);
    }
  }, [location.state]);
  useEffect(() => {
    socket.on("pulseupdate", async (pulz) => {
      setPtray(true);
      const res = await axios.get("https://server-101-jcvh.onrender.com/feed");
      const invertedArray = res.data.slice().reverse();
      setFeed(invertedArray);
    });
  });

  useEffect(() => {
    socket.emit("private message", email);
  }, [email]);
  useEffect(() => {
    socket.on("rece", (msg) => {
      setMtray(mtray + 1);
    });
  });
  useEffect(() => {
    socket.on("ntrayer", (msg) => {
      setNtray(ntray + 1);
    });
  });

  useEffect(() => {
    socket.on("proftray", (msg) => {
      setProftray(proftray + 1);
    });
  });

  useEffect(() => {
    async function rr() {
      const res = await axios.get("https://server-101-jcvh.onrender.com/feed");
      const invertedArray = res.data.slice().reverse();
      setFeed(invertedArray);
    }
    rr();
  }, []);

  function handlechild(props) {
    setDizplay(props);
  }
  return (
    <div className="plcont">
      <div className="prlcont1">
        <img
          src={profpic}
          height="100%"
          style={{ borderRadius: "4px", display: "inline-flex" }}
        ></img>
        <div className="prlcont11">
          <div
            className="prlcont12"
            style={{ borderTop: "4px orangered solid", paddingTop: "1%" }}
          >
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                };
                nav("/pulse", { state: dat });
              }}
            >
              <Badge color="success" variant={ptray ? "dot" : "standard"}>
                <DynamicFeedIcon style={{ color: "orangered" }} />
              </Badge>
            </a>
          </div>

          <div className="prlcont12">
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                  profilepic: profilepic,
                };

                nav("/messages", { state: dat });
              }}
            >
              <Badge badgeContent={mtray} max={2} color="secondary">
                <MessageIcon />
              </Badge>
            </a>
          </div>

          <div className="prlcont12">
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                };
                nav("/notification", { state: dat });
              }}
            >
              <Badge badgeContent={ntray} max={2} color="error">
                <NotificationsActiveIcon />
              </Badge>
            </a>
          </div>
          <div className="prlcont12a">
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                };
                nav("/profile", { state: dat });
              }}
            >
              <img
                src={profilepic}
                height="100%"
                width="40px"
                style={{
                  borderRadius: "50%",
                  display: "inline-flex",
                  objectFit: "contain",
                }}
              ></img>
            </a>
          </div>
        </div>
      </div>

      <div className="plcont1">
        <Upload
          profilepic={profilepic}
          vocus={handleFocus}
          username={username}
          profession={profession}
          email={email}
        />
      </div>
      <div className="plcont2">
        {feed &&
          feed.map((data, index) => {
            const arr = [...feed];

            const isImage = ["jpg", "jpeg", "png", "gif"].some(
              (extension) =>
                data.upload && data.upload.toLowerCase().endsWith(extension)
            );

            const isVideo = ["mp4", "webm", "ogg"].some(
              (extension) =>
                data.upload && data.upload.toLowerCase().endsWith(extension)
            );

            const msgcount = data.messages.length;
            const likescount = data.likes.length;
            const click = true;
            const postid = data._id;
            let hg = false;
            const filter = arr.find((obj) => obj._id === postid);
            //filter has an object
            const yy = filter.likes.map((fil) => {
              if (fil.email === myemail) {
                hg = true;
                return hg;
              } else {
                hg = false;
                return hg;
              }
            });

            let followj = false;

            const vv = filter.followedBy.map((fol) => {
              if (fol.vemail === myemail) {
                followj = true;
                return followj;
              } else {
                followj = false;
                return followj;
              }
            });

            function handleClick(id) {
              const modify = arr.find((obj) => obj._id === id);

              if (likescount !== 0) {
                modify.likes.map((like) => {
                  if (like.email === myemail) {
                    const updatedArray = modify.likes.filter(
                      (obj) => obj.email !== myemail
                    );

                    const modified = { ...modify, likes: updatedArray };

                    const updated = arr.map((obj) =>
                      obj._id === id ? modified : obj
                    );
                    setFeed(updated);
                    const lk = {
                      id: postid,
                      email: myemail,
                    };
                    axios
                      .post(
                        "https://server-101-jcvh.onrender.com/unlike",
                        { lk },
                        {
                          headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                          },
                        }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log(err);
                      });
                  } else {
                    const lkp = {
                      id: postid,
                      email: data.email,
                      username: data.username,
                      vusername: username,
                      vemail: email,
                      vtime: formattedDate,
                      profpic: data.profpic,
                    };
                    const modified = {
                      ...modify,
                      likes: [...modify.likes, lkp],
                    };

                    const updated = arr.map((obj) =>
                      obj._id === id ? modified : obj
                    );
                    setFeed(updated);

                    axios
                      .post(
                        "https://server-101-jcvh.onrender.com/like",
                        { lkp },
                        {
                          headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                          },
                        }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                });
              } else {
                const lk = {
                  id: postid,
                  email: myemail,
                  profpic: profilepic,
                };
                const modified = { ...modify, likes: [...modify.likes, lk] };

                const updated = arr.map((obj) =>
                  obj._id === id ? modified : obj
                );
                setFeed(updated);
                axios
                  .post(
                    "https://server-101-jcvh.onrender.com/like",
                    { lk },
                    {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                    }
                  )
                  .then((res) => {})
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }

            const vollow = (id) => {
              const modifi = arr.find((obj) => obj._id === id);
              const det = {
                id: postid,
                email: data.email,
                username: data.username,
                vusername: username,
                vemail: email,
                vtime: formattedDate,
                profpic: data.profpic,
              };

              const modifieda = {
                ...modifi,
                followedBy: [...modifi.followedBy, det],
              };

              const updateda = arr.map((obj) =>
                obj._id === id ? modifieda : obj
              );
              setFeed(updateda);
              axios
                .post(
                  "https://server-101-jcvh.onrender.com/follow",
                  { det },
                  {
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                  }
                )
                .then((res) => {})
                .catch((err) => {
                  console.log(err);
                });
            };

            function unvollow(id) {
              const modifi = arr.find((obj) => obj._id === id);
              const det = { id: postid, vemail: myemail, profpic: profilepic };

              const updatedArra = modifi.followedBy.filter(
                (obj) => obj.vemail !== myemail
              );

              const modifie = { ...modifi, followedBy: updatedArra };

              const updatef = arr.map((obj) =>
                obj._id === id ? modifie : obj
              );
              setFeed(updatef);
              axios
                .post(
                  "https://server-101-jcvh.onrender.com/unfollow",
                  { det },
                  {
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                  }
                )
                .then((res) => {})
                .catch((err) => {
                  console.log(err);
                });
            }

            //video logic
            let vido = document.getElementById(data._id);
            let rr = false;
            if (vido) {
              vido.currentTime = 10;
            }
            const minutes = Math.floor(vido && vido.duration / 60);
            const seconds = Math.floor(vido && vido.duration % 60);
            //share logic

            return (
              <div className="plcont21" key={index}>
                <div className="plcont22">
                  <a
                    onClick={() => {
                      const visitor = {
                        email: data.email,
                        username: data.username,
                        vusername: username,
                        vemail: email,
                        vtime: formattedDate,
                        profpic: profilepic,
                      };

                      socket.emit("ntray", visitor);

                      axios
                        .post(
                          "https://server-101-jcvh.onrender.com/visit",
                          { visitor },
                          {
                            headers: {
                              "Content-Type":
                                "application/x-www-form-urlencoded",
                            },
                          }
                        )
                        .then((res) => {})
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                  >
                    <div className="plcont24">
                      <a
                        onClick={() => {
                          const yuy = {
                            email: email,
                            profession: profession,
                            username: username,
                            profilepic: profilepic,
                            about: about,
                            receiveremail: data.email,
                            receiverpic: data.profpic,
                            receiverusername: data.username,
                          };
                          nav("/gprofile", { state: yuy });
                        }}
                        style={{ display: "inline-flex", width: "100%" }}
                      >
                        <img
                          src={data.profpic}
                          height="50px"
                          width="50px"
                          style={{ objectFit: "contain" }}
                        ></img>
                        <div className="plcont241">
                          <h4>{data.username}</h4>

                          <h6>{data.work}</h6>
                        </div>
                      </a>
                      {followj ? (
                        <button
                          style={{
                            backgroundColor: "#900603",
                            color: "white",
                            borderRadius: "6px",
                            borderWidth: "0",
                            padding: "0 4%",
                            marginRight: "2%",
                            height: "30px",
                          }}
                          onClick={() => {
                            unvollow(data._id);
                          }}
                        >
                          Unfollow
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: "#900603",
                            color: "white",
                            borderRadius: "6px",
                            borderWidth: "0",
                            padding: "0 4%",
                            marginRight: "2%",
                            height: "30px",
                          }}
                          onClick={() => {
                            vollow(data._id);
                          }}
                        >
                          Follow
                        </button>
                      )}
                    </div>
                  </a>

                  <div className="plcont242">
                    <a
                      onClick={() => {
                        setDizplay(true);

                        setMessages(data.messages);
                      }}
                    >
                      <div style={{ whiteSpace: "pre-line" }}>{data.post}</div>

                      {isImage && <img src={data.upload} width="100%"></img>}
                    </a>
                    {isVideo && (
                      <div>
                        <a
                          onClick={() => {
                            if (vido && vido.paused !== true) {
                              vido.pause();
                            } else if (
                              vido &&
                              vido.currentTime > 1 &&
                              vido.paused === true &&
                              vido.currentTime !== 10
                            ) {
                              vido.play();
                            } else {
                              vido.currentTime = 1;
                              vido.play();
                            }
                          }}
                        >
                          <video
                            id={data._id}
                            width="100%"
                            height="50%"
                            src={data.upload}
                          ></video>
                          <h6
                            style={{
                              marginTop: "-4%",
                              color: "green",
                              textAlign: "right",
                              marginRight: "2%",
                            }}
                          >
                            {minutes}:{seconds}
                          </h6>
                        </a>
                      </div>
                    )}
                  </div>

                  <div className="plcont25">
                    <div className="plcont26">
                      <button
                        style={{
                          backgroundColor: hg ? "blue" : "whitesmoke",
                          border: "#ccc 1px solid ",
                          borderRadius: "4px",
                          marginRight: "1%",
                          marginLeft: "2%",
                        }}
                        onClick={() => {
                          handleClick(data._id);
                        }}
                      >
                        <ThumbUpOutlinedIcon
                          fontSize="smaller"
                          style={{ color: hg ? "white" : "black" }}
                        />
                      </button>
                      <h6>{likescount}</h6>

                      <button
                        style={{
                          border: "#ccc 1px solid ",
                          borderRadius: "4px",
                          marginLeft: "1%",
                          marginRight: "1%",
                        }}
                        onClick={() => {
                          if (dizplay === data._id) {
                            setDizplay("");
                          } else {
                            setDizplay(data._id);
                          }
                          setMessages(data.messages);
                          const rr = {
                            email: data.email,
                            username: username,
                            profpic: profilepic,
                            id: data._id,
                            pusername: data.username,
                            vemail: email,
                            vtime: formattedDate,
                            vprofpic: data.profpic,
                          };
                          setClicked(rr);
                        }}
                      >
                        <ChatBubbleOutlineOutlinedIcon
                          fontSize="smaller"
                          style={{
                            color: "black",
                          }}
                        />
                      </button>

                      <h6>{msgcount}</h6>

                      <a
                        className="plcont26a"
                        onClick={() => {
                          if (data.upload) {
                            saveAs(data.upload, "trussnetworks");
                          }
                        }}
                        style={{ display: "inline-flex" }}
                      >
                        <DownloadIcon fontSize="small" />
                      </a>
                      <a
                        className="plcont26b"
                        style={{ display: "inline-flex" }}
                        onClick={() => {
                          setTre(data._id);
                        }}
                      >
                        <ShareIcon
                          fontSize="smaller"
                          style={{ marginLeft: "30%", marginRight: "20%" }}
                        />

                        <h5>SHARE</h5>
                      </a>
                    </div>
                  </div>
                  <a
                    onClick={() => {
                      setTre("");
                    }}
                  >
                    <div
                      style={{
                        display: tre === data._id ? "flex" : "none",
                      }}
                    >
                      <WorkplaceShareButton url={data.upload}>
                        <WorkplaceIcon size={32} round={true}></WorkplaceIcon>
                      </WorkplaceShareButton>
                      <WhatsappShareButton url={data.upload}>
                        <WhatsappIcon size={32} round={true}></WhatsappIcon>
                      </WhatsappShareButton>
                      <VKShareButton url={data.upload}>
                        <VKIcon size={32} round={true}></VKIcon>
                      </VKShareButton>
                      <ViberShareButton url={data.upload}>
                        <ViberIcon size={32} round={true}></ViberIcon>
                      </ViberShareButton>

                      <TwitterShareButton url={data.upload}>
                        <TwitterIcon size={32} round={true}></TwitterIcon>
                      </TwitterShareButton>
                      <EmailShareButton url={data.upload}>
                        <EmailIcon size={32} round={true} />
                      </EmailShareButton>
                      <FacebookShareButton url={data.upload}>
                        <FacebookIcon size={32} round={true}></FacebookIcon>
                      </FacebookShareButton>
                      <GabShareButton url={data.upload}>
                        <GabIcon size={32} round={true}></GabIcon>
                      </GabShareButton>
                      <HatenaShareButton url={data.upload}>
                        <HatenaIcon size={32} round={true}></HatenaIcon>
                      </HatenaShareButton>
                      <InstapaperShareButton url={data.upload}>
                        <InstapaperIcon size={32} round={true}></InstapaperIcon>
                      </InstapaperShareButton>
                      <LineShareButton url={data.upload}>
                        <LineIcon size={32} round={true}></LineIcon>
                      </LineShareButton>
                      <LinkedinShareButton url={data.upload}>
                        <LinkedinIcon size={32} round={true}></LinkedinIcon>
                      </LinkedinShareButton>
                      <LivejournalShareButton url={data.upload}>
                        <LivejournalIcon
                          size={32}
                          round={true}
                        ></LivejournalIcon>
                      </LivejournalShareButton>
                      <MailruShareButton url={data.upload}>
                        <MailruIcon size={32} round={true}></MailruIcon>
                      </MailruShareButton>
                      <OKShareButton url={data.upload}>
                        <OKIcon size={32} round={true}></OKIcon>
                      </OKShareButton>
                      <PinterestShareButton url={data.upload}>
                        <PinterestIcon size={32} round={true}></PinterestIcon>
                      </PinterestShareButton>
                      <PocketShareButton url={data.upload}>
                        <PocketIcon size={32} round={true}></PocketIcon>
                      </PocketShareButton>
                      <RedditShareButton url={data.upload}>
                        <RedditIcon size={32} round={true}></RedditIcon>
                      </RedditShareButton>
                      <TelegramShareButton url={data.upload}>
                        <TelegramIcon size={32} round={true}></TelegramIcon>
                      </TelegramShareButton>
                      <TumblrShareButton url={data.upload}>
                        <TumblrIcon size={32} round={true}></TumblrIcon>
                      </TumblrShareButton>
                    </div>
                  </a>
                  <div
                    style={{ display: dizplay === data._id ? "block" : "none" }}
                  >
                    <Comment msg={messages} click={clicked} />
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <div className="plcont3">
        <a
          onClick={() => {
            const dat = {
              email: email,
              username: username,
              about: about,
              profession: profession,
            };
            nav("/profile", { state: dat });
          }}
        >
          <div className="pllcont1">
            <img
              alt="kkl"
              src={coverpic}
              height="36%"
              width="100%"
              style={{ display: "block" }}
            ></img>
            <div
              style={{
                position: "absolute",
                marginTop: "-10%",
                display: "block",
                borderRadius: "50%",
                alignSelf: "center",
                backgroundColor: "white",
                width: "50px",
                height: "50px",
                right: "40%",
              }}
            >
              <img
                src={profilepic}
                alt="profilepic"
                width="40px"
                height="40px"
                style={{
                  borderRadius: "20px",
                  marginTop: "5px",
                  objectFit: "contain",
                }}
              ></img>
            </div>
            <h4>{username}</h4>
            <h6>{profession}</h6>
            <h6>{about}</h6>
          </div>
        </a>
      </div>
      <div className="prcont8">
        <img
          src={profpic}
          height="4%"
          width="6%"
          style={{ borderRadius: "4px", display: "inline-flex" }}
        ></img>

        <div className="prcont81">
          <h5>About Us</h5>
          <h5>Community Guidlines</h5>
        </div>

        <div className="prcont81">
          <h5>Privacy & Terms</h5>

          <h5>Help Center</h5>
        </div>

        <div className="prcont81">
          <h5>Language</h5>
          <h5>English</h5>
        </div>
      </div>
    </div>
  );
}
export default Pulse;
/*
const updatedArray = feed.map((item) => {
              if (item.email === mail) {
                let integerValue = parseInt(item.likes, 10);

                let resultInteger = integerValue + 1;

                let resultString = resultInteger.toString();

                return { ...item, likes: resultString };
              }
              return item;
            });

            setFeed(updatedArray);


*/
