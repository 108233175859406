//home, about us, team, contact us, login, get started, partners, testimonials, no of users.
import React from "react";
import { useNavigate } from "react-router-dom";
import "./landingpage.css";
import pic from "../assets/lp1.png";

import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EmailIcon from "@mui/icons-material/Email";
import ModeCommentIcon from "@mui/icons-material/ModeComment";

function Landingpage(params) {
  const nav = useNavigate();
  return (
    <div className="ldcont">
      <div className="ldcont1">
        <a onClick={() => {}}>
          <h2>Home</h2>
        </a>
        <a onClick={() => {}}>
          <h2>About us</h2>
        </a>
        <a onClick={() => {}}>
          <h2>Team</h2>
        </a>
        <a onClick={() => {}}>
          <h2>Contact us </h2>
        </a>
        <a
          onClick={() => {
            nav("/login");
          }}
        >
          <h2>login</h2>
        </a>
      </div>

      <div className="ldcont2">
        <div className="ldcont21">
          <h1>Unlock the Full Potential of your Career with Truss Networks!</h1>
          <a
            onClick={() => {
              nav("/signup");
            }}
          >
            <h2>Get Started</h2>
          </a>
        </div>

        <div className="ldcont22">
          <img
            src={pic}
            height="100%"
            width="100%"
            style={{
              objectFit: "contain",
              textAlign: "right",
            }}
          ></img>
        </div>
      </div>

      <div className="bod8">
        <div className="bod81">
          <div className="b81">
            <h4>Contact Us</h4>
            <div className="b811">
              <a href="">
                <EmailIcon
                  style={{
                    fontSize: 22,
                    color: "grey",
                    marginRight: "14px",
                    marginTop: "10px",
                  }}
                />
                <h5>Email</h5>
              </a>
            </div>
            <div className="b811">
              <a href="">
                <SupportAgentIcon
                  style={{ fontSize: 22, color: "grey", marginRight: "14px" }}
                />
                <h5>Help Center</h5>
              </a>
            </div>
            <div className="b811">
              <a href="">
                <ModeCommentIcon
                  style={{
                    fontSize: 22,
                    color: "grey",
                    marginRight: "14px",
                  }}
                />
                <h5>Live Chat</h5>
              </a>
            </div>
          </div>
          <div className="b81">
            <h4>About Us</h4>
            <a href="">
              <h5>Matching AI</h5>
            </a>
            <a href="">
              <h5>Financing</h5>
            </a>
            <a href="">
              <h5>Partners</h5>
            </a>
          
          </div>

          <div className="b81">
            <h4>Resources</h4>
            <a href="">
              <h5>Cookie Policy</h5>
            </a>
            <a href="">
              <h5>Terms & Conditions</h5>
            </a>
            <a href="">
              <h5>Company Blog</h5>
            </a>
          </div>
        </div>

        <div className="b82">
          <h4>Follow Us</h4>
          <a href="https://wa.me/254741217101">
            <WhatsAppIcon
              className="whats"
              style={{ fontSize: 28, color: "green", marginRight: "14px" }}
            />
          </a>
          <a href="https://www.facebook.com/thestartupskitchen">
            <FacebookIcon
              style={{ fontSize: 28, color: "white", marginRight: "14px" }}
            />
          </a>
          <a href="https://www.linkedin.com/company/96388899/">
            <LinkedInIcon
              style={{
                fontSize: 28,
                color: "blue",
                marginRight: "14px",
              }}
            />
          </a>

          <a href="https://www.instagram.com/thestartups.kitchen/">
            <InstagramIcon
              style={{ fontSize: 28, color: "purple", marginRight: "14px" }}
            />
          </a>
          <a href="https://www.youtube.com/channel/UCrhQONwfg7B0YNLNXTbtTvw">
            <YouTubeIcon
              style={{ fontSize: 34, color: "red", marginRight: "14px" }}
            />
          </a>
        </div>
        <div className="b82">
          <h5>Truss Networks ©2023 All Rights Reserved</h5>
        </div>
      </div>
    </div>
  );
}
export default Landingpage;
