import React, { useEffect, useState } from "react";
import "../views/pulse.css";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { socket } from "./Pulse";
function Comment(props) {
  const clicked = props.click;

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState();
  const [myusername, setMyusername] = useState("Dan Sabai");
  const [dizplay, setDizplay] = useState(true);
  useEffect(() => {
    setMessages(props.msg);
  }, [props.msg]);

  if (clicked) {
    function handleSend(e) {
      e.preventDefault();

      const meso = {
        id: clicked.id,
        profpic: clicked.profpic,
        username: clicked.username,
        message: message,
        email: clicked.email,
        pusername: clicked.pusername,
        vemail: clicked.vemail,
        vtime: clicked.vtime,
        vprofpic: clicked.vprofpic,
      };
      socket.emit("newpulse", meso);
      setMessages([...messages, meso]);
      axios
        .post(
          "http://lcalhost:5000/meso",
          { meso },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }

    return (
      <div className="plmsg">
        <div className="plmsg1">
          {messages &&
            messages.map((msg) => {
              return (
                <div className="plmsg11">
                  <img
                    src={msg.profpic}
                    width="40px"
                    height="40px"
                    alt="khju"
                    style={{ objectFit: "contain" }}
                  ></img>
                  <div className="plmsg12">
                    <h4>{msg.username}</h4>
                    <h5>{msg.message}</h5>
                  </div>
                </div>
              );
            })}
        </div>

        <div className="plmsg2" style={{}}>
          <form
            onSubmit={(e) => {
              handleSend(e);
              setMessage("");
            }}
          >
            <input
              type="text"
              id="comment"
              name="comment"
              value={message}
              placeholder="Comment Here!"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            ></input>
            <button type="submit" id={clicked.id}>
              Send
            </button>
          </form>
        </div>
      </div>
    );
  }
}
export default Comment;
