import React, { useEffect, useState } from "react";
import "../views/notification.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import axios from "axios";
import PersonIcon from "@mui/icons-material/Person";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge } from "@mui/material";
import { socket } from "./Pulse";
function Notification(params) {
  const location = useLocation();
  const nav = useNavigate();
  const profpic =
    "https://res.cloudinary.com/sabai/image/upload/v1715242363/deqjunruxn3rfgk4vq0v.png";

  const [visits, setVisits] = useState([]);
  const [views, setViews] = useState(0);
  const email = location.state.email;
  const username = location.state.username;
  const about = location.state.about;
  const profession = location.state.profession;

  const [ntray, setNtray] = useState(0);
  const [mtray, setMtray] = useState(0);
  const [ptray, setPtray] = useState(false);
  const [proftray, setProftray] = useState("0");

  useEffect(() => {
    socket.emit("private message", email);
  }, [email]);
  useEffect(() => {
    socket.on("rece", (msg) => {
      setMtray(mtray + 1);
    });
  });
  useEffect(() => {
    socket.on("ntrayer", (msg) => {
      setNtray(ntray + 1);
    });
  });
  useEffect(() => {
    socket.on("pulseupdate", async (pulz) => {
      setPtray(true);
    });
  });
  useEffect(() => {
    socket.on("proftray", (msg) => {
      setProftray(proftray + 1);
    });
  });

  useEffect(() => {
    axios
      .post(
        "https://server-101-jcvh.onrender.com/visits",
        { email },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        setViews(res.data.length);
        const invertedArray = res.data.slice().reverse();
        setVisits(invertedArray);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  return (
    <div className="ncont">
      <div className="prncont1">
        <img
          src={profpic}
          height="100%"
          style={{ borderRadius: "4px", display: "inline-flex" }}
        ></img>
        <div className="prncont11">
          <div className="prncont12">
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                };
                nav("/pulse", { state: dat });
              }}
            >
              <Badge color="success" variant={ptray ? "dot" : "standard"}>
                <DynamicFeedIcon />
              </Badge>
            </a>
          </div>

          <div className="prncont12">
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                };

                nav("/messages", { state: dat });
              }}
            >
              <Badge badgeContent={mtray} max={2} color="secondary">
                <MessageIcon />
              </Badge>
            </a>
          </div>

          <div
            className="prncont12"
            style={{ borderTop: "4px orangered solid", paddingTop: "1%" }}
          >
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                };
                nav("/notification", { state: dat });
              }}
            >
              <Badge badgeContent={ntray} max={2} color="error">
                <NotificationsActiveIcon
                  fontSize="small"
                  style={{ color: "orangered" }}
                />
              </Badge>
            </a>
          </div>
        </div>
      </div>

      <div className="ncont1">
        <div className="ncont11">
          <h6>NOTIFICATIONS</h6>
          <h6>NOTIFICATION SETTINGS</h6>
        </div>

        <div className="ncont12">
          <div className="ncont121">
            <h6>YOUR DASHBOARD</h6>
            <h6 style={{ marginLeft: "22%", borderBottom: "1px solid black" }}>
              GO TO STATS
            </h6>
          </div>

          <h1>{views}</h1>
          <h6>profile views</h6>

          <h1>15</h1>
          <h6>posts views</h6>

          <h1>9</h1>
          <h6>search appearances</h6>
        </div>
      </div>

      <div className="ncont2">
        {visits &&
          visits.map((dat) => {
            return (
              <div>
                <div className="ncont2p">
                  <img
                    src={dat.profpic}
                    alt="picha"
                    height="40px"
                    width="40px"
                    style={{ borderRadius: "50%", objectFit: "contain" }}
                  ></img>
                  <div className="ncont21">
                    <h5>
                      <h5
                        style={{
                          display: "inline-flex",
                          fontSize: "small",
                          fontWeight: "bold",
                        }}
                      >
                        {dat.vusername}
                      </h5>
                      viewed your profile
                    </h5>
                    <h6>{dat.vtime}</h6>
                  </div>

                  <MoreHorizIcon
                    style={{ position: "absolute", right: "2%" }}
                  />
                </div>
              </div>
            );
          })}
      </div>

      <div className="prcont8">
        <img
          src={profpic}
          height="4%"
          width="6%"
          style={{ borderRadius: "4px", display: "inline-flex" }}
        ></img>

        <div className="prcont81">
          <h5>About Us</h5>
          <h5>Community Guidlines</h5>
        </div>

        <div className="prcont81">
          <h5>Privacy & Terms</h5>
          <h5>Help Center</h5>
        </div>

        <div className="prcont81">
          <h5>Language</h5>
          <h5>English</h5>
        </div>
      </div>
    </div>
  );
}
export default Notification;
