import React, { useEffect, useRef, useState } from "react";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import EditIcon from "@mui/icons-material/Edit";
import "./profile.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import AddIcon from "@mui/icons-material/Add";
import { Badge } from "@mui/material";
import { socket } from "./Pulse";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";

function Profile(params) {
  const nav = useNavigate();
  const location = useLocation();
  const emaila = location.state.email;
  const username = location.state.username;
  const [email, setEmail] = useState(emaila);
  const [company, setCompany] = useState();
  const [about, setAbout] = useState();
  const [profession, setProfession] = useState();
  const [profilepica, setProfilepica] = useState();
  const [coverpic, setCoverpic] = useState();
  const [experience, setExperience] = useState([]);
  const [skills, setSkills] = useState([]);
  const [ecl, setEcl] = useState(false);
  const [scl, setScl] = useState(false);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [role, setRole] = useState();
  const [roledesc, setRoledesc] = useState();
  const [newskill, setNewskill] = useState();
  const [years, setYears] = useState();

  const cloudinaryRef = useRef();
  const widgetRef = useRef();
  const widgetRefa = useRef();
  let proc = null;
  const [glik, setGlik] = useState(false);
  const [alive, setAlive] = useState(false);
  const [ntray, setNtray] = useState(0);
  const [mtray, setMtray] = useState(0);
  const [ptray, setPtray] = useState(false);
  const [proftray, setProftray] = useState("0");

  useEffect(() => {
    socket.emit("private message", email);
  }, [email]);
  useEffect(() => {
    socket.on("rece", (msg) => {
      setMtray(mtray + 1);
    });
  });
  useEffect(() => {
    socket.on("ntrayer", (msg) => {
      setNtray(ntray + 1);
    });
  });

  useEffect(() => {
    socket.on("proftray", (msg) => {
      setProftray(proftray + 1);
    });
  });
  useEffect(() => {
    socket.on("pulseupdate", async (pulz) => {
      setPtray(true);
    });
  });
  useEffect(() => {
    axios
      .post(
        "https://server-101-jcvh.onrender.com/profile",
        { email },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        setAbout(res.data.about);
        setProfession(res.data.profession);
        setExperience(res.data.experience);
        setSkills(res.data.skills);
        setCoverpic(res.data.coverpic);
        setProfilepica(res.data.profpic);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRefa.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "sabai",
        uploadPreset: "aiea32d9",
      },

      function (error, result) {
        if (result.event === "success") {
          console.log(result.info.secure_url);
          setCoverpic(result.info.secure_url);
        }
      }
    );
  }, []);
  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "sabai",
        uploadPreset: "aiea32d9",
      },

      function (error, result) {
        if (result.event === "success") {
          setProfilepica(result.info.secure_url);
        }
      }
    );
  }, [proc]);

  function handleUpdate() {
    if (profilepica !== "" && coverpic !== "") {
      const tosend = {
        emaila: emaila,
        email: email,
        profpicupdate: profilepica,
        coverpicupdate: coverpic,
        about: about,
        profession: profession,
      };
      axios
        .post(
          "https://server-101-jcvh.onrender.com/profileUpdate",
          { tosend },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function handleExperienceupdate(params) {
    if (role !== "" && company !== "" && start !== "") {
      const tosend = {
        email: email,
        experience: {
          company: company,
          role: role,
          roledesc: roledesc,
          start: start,
          end: alive ? "Currently" : end,
        },
      };
      const skilup = {
        company: company,
        role: role,
        roledesc: roledesc,
        start: start,
        end: alive ? "Currently" : end,
      };
      setExperience([...experience, skilup]);

      axios
        .post(
          "https://server-101-jcvh.onrender.com/experienceupdate",
          { tosend },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }
  function handleSkillupdate(params) {
    if (newskill !== "" && years !== "") {
      const tosend = {
        email: email,
        newskill: { skill: newskill, years: years },
      };
      axios
        .post(
          "https://server-101-jcvh.onrender.com/skillupdate",
          { tosend },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const profpic =
    "https://res.cloudinary.com/sabai/image/upload/f_auto,q_auto/mmd2c14kj1hr2wkguew3";
  const logo =
    "https://res.cloudinary.com/sabai/image/upload/v1715242363/deqjunruxn3rfgk4vq0v.png";

  return (
    <div className="prcont">
      <div className="prcont1">
        <img
          src={logo}
          height="100%"
          style={{ borderRadius: "4px", display: "inline-flex" }}
        ></img>
        <div className="prcont11">
          <div className="prlcont12">
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                };
                nav("/pulse", { state: dat });
              }}
            >
              <Badge color="success" variant={ptray ? "dot" : "standard"}>
                <DynamicFeedIcon />
              </Badge>
            </a>
          </div>

          <div className="prlcont12">
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                };

                nav("/messages", { state: dat });
              }}
            >
              <Badge badgeContent={mtray} max={2} color="secondary">
                <MessageIcon />
              </Badge>
            </a>
          </div>

          <div className="prlcont12">
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                };
                nav("/notification", { state: dat });
              }}
            >
              <Badge badgeContent={ntray} max={2} color="error">
                <NotificationsActiveIcon />
              </Badge>
            </a>
          </div>
        </div>
      </div>
      <a
        onClick={() => {
          setGlik(true);
        }}
      >
        <div className="prcont2">
          <div className="prcont21">
            <img
              src={coverpic}
              height="300px"
              width="100%"
              style={{ objectFit: "fill" }}
            ></img>
            <a
              onClick={() => {
                setGlik(true);
              }}
            >
              <EditIcon
                fontSize="small"
                style={{
                  marginLeft: "92%",
                  marginTop: "1%",
                  paddingBottom: "4%",
                }}
              />
            </a>
          </div>
          <img
            src={profilepica}
            height="80%"
            width="16%"
            style={{
              borderRadius: "50%",
              border: "6px white solid",
              marginTop: "-20%",
              marginLeft: "4%",
              objectFit: "contain",

              backgroundColor: "white",
            }}
          ></img>
          <div className="prcont22">
            <h4>{username}</h4>
            <h5>{profession}</h5>
            <h5 className="prcont22h6">{about}</h5>
          </div>
        </div>
      </a>

      <div className="prcont5">
        <h3>Work Experience</h3>
        <a
          onClick={() => {
            setScl(false);
            setEcl(true);
          }}
        >
          <div className="prcont511">
            <AddIcon style={{}} />
          </div>
        </a>

        {experience &&
          experience.map((data, index) => {
            return (
              <div className="prcont5p" key={index}>
                <div>
                  <div className="prcont51">
                    <h4>{data.company}</h4>
                    <h4>{data.role}</h4>
                    <h6>{data.start + " " + "--" + " " + data.end}</h6>
                    <h5>{data.roledesc}</h5>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <div className="prcont6">
        <h3>Skills</h3>
        <a
          onClick={() => {
            setScl(true);
            setEcl(false);
          }}
        >
          <div className="prcont511">
            <AddIcon style={{}} />
          </div>
        </a>
        {skills &&
          skills.map((data, index) => {
            return (
              <div className="prcont6" key={index}>
                <div style={{ display: "inline-flex", width: "100%" }}>
                  <h5>{data.skill}</h5>
                  <h5 style={{ fontWeight: "lighter" }}>
                    {data.years + " " + "Years"}
                  </h5>
                </div>
              </div>
            );
          })}
      </div>

      <div className="prcont8">
        <img
          src={logo}
          height="2%"
        
          style={{ borderRadius: "4px", display: "inline-flex" }}
        ></img>

        <div className="prcont81">
          <h5>About Us</h5>
          <h5>Community Guidlines</h5>
        </div>
        <div className="prcont81">
          <h5>Privacy & Terms</h5>

          <h5>Help Center</h5>
        </div>
        <div className="prcont81">
          <h5>Language</h5>
          <h5>English</h5>
        </div>
      </div>

      <div
        style={{
          display: glik ? "flex" : "none",
          position: "absolute",
          top: "10%",
          width: "100%",
          height: "200%",
          backgroundColor: "white",
          paddingBottom: "10%",
        }}
      >
        <div className="prdisp" style={{ display: "block" }}>
          <img
            src={coverpic}
            height="20%"
            width="100%"
            style={{
              borderRadius: "4px",
              display: "block",
              objectFit: "contain",
            }}
          ></img>
          <button
            onClick={() => {
              widgetRefa.current.open();
            }}
          >
            Update Cover Picture
          </button>
          <img
            src={profilepica}
            height="20%"
            width="100%"
            style={{
              borderRadius: "4px",
              display: "inline-flex",
              objectFit: "contain",
            }}
          ></img>
          <button
            onClick={() => {
              widgetRef.current.open();
            }}
          >
            Change Profile Photo
          </button>
          <div className="prdisp11">
            <label>About</label>
            <textarea
              value={about}
              onChange={(e) => {
                setAbout(e.target.value);
              }}
            ></textarea>
            <label> Email</label>
            <input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            ></input>
            <label> Profession</label>
            <input
              value={profession}
              onChange={(e) => {
                setProfession(e.target.value);
              }}
            ></input>
          </div>

          <button
            onClick={() => {
              handleUpdate();
              setGlik(false);
            }}
            style={{ color: "white", backgroundColor: "green" }}
          >
            Save
          </button>
        </div>
      </div>
      <div style={{ display: ecl ? "flex" : "none", textAlign: "center" }}>
        <div className="prcont9">
          <a
            onClick={() => {
              setEcl(false);
            }}
          >
            <CloseIcon
              style={{
                position: "fixed",
                top: "3%",
                left: "2%",
                fontSize: "50px",
                borderRadius: "4px",
                backgroundColor: "#ccc",
              }}
            />
          </a>
          <label>Company</label>
          <input
            type="text"
            id="role"
            name="role"
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
            }}
            placeholder="Name of the company?"
          ></input>
          <label>Role</label>
          <input
            type="text"
            id="role"
            name="role"
            value={role}
            onChange={(e) => {
              setRole(e.target.value);
            }}
            placeholder="What was your Role?"
          ></input>
          <label>Give a brief description of your Role</label>
          <textarea
            value={roledesc}
            onChange={(e) => {
              setRoledesc(e.target.value);
            }}
          ></textarea>
          <label>Start Date</label>
          <input
            type="date"
            id="start"
            name="start"
            value={start}
            onChange={(e) => {
              setStart(e.target.value);
            }}
          ></input>
          <label>Currently</label>
          <a
            onClick={() => {
              if (alive === false) {
                setAlive(true);
              } else {
                setAlive(false);
              }
            }}
          >
            {alive ? (
              <CheckBoxIcon fontSize="large" />
            ) : (
              <CheckBoxOutlineBlankIcon fontSize="large" />
            )}
          </a>
          <label>End Date</label>
          <input
            type="date"
            id="end"
            name="end"
            value={end}
            onChange={(e) => {
              setEnd(e.target.value);
            }}
          ></input>
          <button
            onClick={() => {
              handleExperienceupdate();
              setEcl(false);
            }}
          >
            Add
          </button>
        </div>
      </div>

      <div style={{ display: scl ? "flex" : "none" }}>
        <div className="prcont10">
          <a
            onClick={() => {
              setScl(false);
            }}
          >
            <CloseIcon
              style={{
                position: "fixed",
                top: "3%",
                left: "2%",
                fontSize: "50px",
                borderRadius: "4px",
                backgroundColor: "#ccc",
              }}
            />
          </a>
          <label>Enter your Skill</label>
          <input
            type="text"
            name="skill"
            id="skill"
            value={newskill}
            onChange={(e) => {
              setNewskill(e.target.value);
            }}
            placeholder="What's your skill?"
          ></input>
          <label>Years of experience</label>
          <input
            type="text"
            id="years"
            name="years"
            value={years}
            onChange={(e) => {
              setYears(e.target.value);
            }}
            placeholder="Number of years "
          ></input>
          <button
            onClick={() => {
              handleSkillupdate();
              setScl(false);
              const skilup = {
                skill: newskill,
                years: years,
              };
              setSkills([...skills, skilup]);
            }}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
}
export default Profile;
