import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "../views/messages.css";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import PersonIcon from "@mui/icons-material/Person";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge } from "@mui/material";
import { socket } from "./Pulse";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Messages() {
  const location = useLocation();
  const nav = useNavigate();

  const email = location.state.email;
  const username = location.state.username;
  const about = location.state.about;
  const profession = location.state.profession;
  const profilepic = location.state.profilepic;
  const [receiverpic, setReceiverpic] = useState(location.state.receiverpic);
  const [receiverusername, setReceiverusername] = useState(
    location.state.receiverusername
  );
  const [userclicked, setUserclicked] = useState(location.state.receiveremail);
  const [ntray, setNtray] = useState(0);
  const [mtray, setMtray] = useState(0);
  const [ptray, setPtray] = useState(false);
  const [proftray, setProftray] = useState("0");
  const [time, setTime] = useState("10:00PM");
  const [message, setMessage] = useState();
  const [allchats, setAllchats] = useState([]);
  const [chatfiltered, setChatfiltered] = useState([]);
  const [chats, setChats] = useState([]);
  const [userg, setUserg] = useState();

  const stateRef = useRef(userclicked);
  const see = useRef(false);
  const stata = useRef(false);
  const bottomRef = useRef(null);
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [allchats]);
  useEffect(() => {
    stateRef.current = userclicked;
  }, [userclicked]);
  const profpic =
    "https://res.cloudinary.com/sabai/image/upload/v1715242363/deqjunruxn3rfgk4vq0v.png";

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  useEffect(() => {
    socket.emit("private message", email);
  }, [email]);

  useEffect(() => {
    socket.on("rece", (msg) => {
      setMtray(mtray + 1);
    });
  });
  useEffect(() => {
    socket.on("ntrayer", (msg) => {
      setNtray(ntray + 1);
    });
  });
  useEffect(() => {
    socket.on("pulseupdate", async (pulz) => {
      setPtray(true);
    });
  });
  useEffect(() => {
    socket.on("proftray", (msg) => {
      setProftray(proftray + 1);
    });
  });
  const dummy = useRef(null);

  const scrollToBottom = () => {
    if (dummy.current) {
      dummy.current.scrollTop = dummy.current.scrollHeight;
    }
  };
  useEffect(() => {
    socket.on("rece", (msg) => {
      if (msg.receiver === email) {
        if (msg.sender === stateRef.current) {
          const chats = {
            email: msg.email,
            message: msg.message,
            sender: msg.sender,
            senderusername: msg.senderusername,
            senderpic: msg.senderpic,
            receiver: msg.receiver,
            receiverusername: msg.receiverusername,
            receiverpic: msg.receiverpic,
            time: msg.time,
            opened: msg.opened,
          };
          setAllchats([...allchats, chats]);
        }
      }
    });
  }, [allchats]);

  useEffect(() => {
    axios
      .post(
        "https://server-101-jcvh.onrender.com/getchats",
        { email },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        setChats(res.data.chats);
        const pure = [];
        res.data.chats.map((dt) => {
          if (dt.sender === email) {
            const tt = {
              email: dt.receiver,
              userpic: dt.receiverpic,
              username: dt.receiverusername,
              message: dt.message,
            };
            pure.push(tt);
          } else if (dt.receiver === email) {
            const tt = {
              email: dt.sender,
              userpic: dt.senderpic,
              username: dt.senderusername,
              message: dt.message,
            };
            pure.push(tt);
          }
        });

        const result = Object.values(
          pure.reduce((acc, obj) => ({ ...acc, [obj.email]: obj }), {})
        );

        setChatfiltered(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [email, chats, chatfiltered, allchats]);

  useEffect(() => {
    if (message === "") {
      scrollToBottom();
    }
  }, [message]);
  useEffect(() => {
    if (dummy.current) {
      scrollToBottom();
    }
  }, [allchats]);

  function handleSubmit(jats) {
    axios
      .post(
        "https://server-101-jcvh.onrender.com/newmsg",
        { jats },

        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        console.log("sent");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handleKeyPress(event) {
    if (event.key === "Enter") {
      document.getElementById("myButton").click();
    }
  }

  function handleClick(rec) {
    setUserclicked(rec);
    const pfinal = [];
    chats.map((cht) => {
      if (cht.sender === rec) {
        pfinal.push(cht);
      } else if (cht.receiver === rec) {
        pfinal.push(cht);
      }
    });

    setAllchats(pfinal);
  }

  useEffect(() => {
    axios
      .post(
        "https://server-101-jcvh.onrender.com/getchats",
        { email },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        if (stata.current === false && userclicked !== undefined) {
          see.current = true;
          const pfinal = [];
          res.data.chats.map((cht) => {
            if (cht.sender === userclicked) {
              pfinal.push(cht);
            } else if (cht.receiver === userclicked) {
              pfinal.push(cht);
            }
          });

          setAllchats(pfinal);
          stata.current = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [email]);

  return (
    <div className="mscont">
      <div className="prmcont1">
        <img
          src={profpic}
          height="100%"
          style={{ borderRadius: "4px", display: "inline-flex" }}
        ></img>
        <div className="prmcont11">
          <div className="prmcont12">
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                };
                nav("/pulse", { state: dat });
              }}
            >
              <Badge color="success" variant={ptray ? "dot" : "standard"}>
                <DynamicFeedIcon />
              </Badge>
            </a>
          </div>
          <div
            className="prmcont12"
            style={{ borderTop: "4px orangered solid", paddingTop: "1%" }}
          >
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                };

                nav("/messages", { state: dat });
              }}
            >
              <Badge badgeContent={mtray} max={2} color="secondary">
                <MessageIcon fontSize="small" style={{ color: "orangered" }} />
              </Badge>
            </a>
          </div>

          <div className="prmcont12">
            <a
              onClick={() => {
                const dat = {
                  email: email,
                  username: username,
                  about: about,
                  profession: profession,
                };
                nav("/notification", { state: dat });
              }}
            >
              <Badge badgeContent={ntray} max={2} color="error">
                <NotificationsActiveIcon />
              </Badge>
            </a>
          </div>
        </div>
      </div>

      <div className="mobilev">
        <div
          className="mscont1"
          style={{ display: see.current ? "none" : "block" }}
        >
          <h4>CHATS</h4>
          {chatfiltered.map((chat) => {
            return (
              <div className="mscont111">
                <div className="mscont11">
                  <button
                    style={{
                      alignItems: "center",
                      display: "inline-flex",
                      borderWidth: "0",
                      backgroundColor: "white",

                      width: "100%",
                    }}
                    onClick={() => {
                      setUserclicked("");
                      setReceiverpic(chat.userpic);
                      setReceiverusername(chat.username);

                      see.current = true;
                      if (chat.email) {
                        handleClick(chat.email);
                      }
                    }}
                  >
                    <img
                      src={chat.userpic}
                      alt="profilepic"
                      width="40px"
                      height="40px"
                      style={{ borderRadius: "20px", objectFit: "contain" }}
                    ></img>
                    <div className="mscont12">
                      <h5>{chat.username}</h5>
                      <h6>{chat.message}</h6>
                    </div>
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        <div
          className="mcont"
          style={{ display: see.current ? "flex" : "none" }}
        >
          <div style={{ display: "block", width: "100%" }}>
            <a
              style={{ display: "inline-flex", height: "50px" }}
              onClick={() => {
                stata.current = true;
                see.current = false;
              }}
            >
              <ArrowBackIcon />
            </a>
            <a
              onClick={() => {
                const yuy = {
                  email: email,
                  profession: profession,
                  username: username,
                  profilepic: profilepic,
                  about: about,
                  receiveremail: userclicked,
                  receiverpic: receiverpic,
                  receiverusername: receiverusername,
                };
                nav("/gprofile", { state: yuy });
              }}
            >
              <div className="mcont1">
                <img
                  src={receiverpic}
                  height="40px"
                  width="40px"
                  style={{
                    borderRadius: "50%",
                    display: "inline-flex",
                    margin: "1%",
                    objectFit: "contain",
                  }}
                ></img>
                <h5 style={{ marginLeft: "1%", fontSize: "small" }}>
                  {receiverusername}
                </h5>
              </div>
            </a>

            <div ref={dummy} className="mcont2">
              {allchats.map((chat, index) => {
                return (
                  <div className="mcont21">
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <h4
                        style={{
                          padding: "4px 4%",
                          backgroundColor:
                            chat.sender === email ? "#1C05B3" : "purple",
                          alignSelf: chat.sender === email ? "end" : "start",
                          borderRadius: "4px",
                          color: "whitesmoke",
                          fontWeight: "400",
                          marginBlockStart: "0",
                          margin: "1% 2%",
                        }}
                      >
                        {chat.message}
                      </h4>
                    </div>
                  </div>
                );
              })}
              <div ref={bottomRef} />
            </div>
            <div className="mcont3">
              <input
                type="text"
                id="message"
                name="message"
                onKeyDown={(event) => {
                  handleKeyPress(event);
                }}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              ></input>
              <a
                id="myButton"
                onClick={() => {
                  if (message !== "") {
                    setAllchats([
                      ...allchats,
                      {
                        email: email,
                        message: message,
                        sender: email,
                        senderpic: profilepic,
                        senderusername: username,
                        receiver: userclicked,
                        receiverusername: receiverusername,
                        receiverpic: receiverpic,
                        time: time,
                        opened: false,
                      },
                    ]);

                    const jats = {
                      email: email,
                      message: message,
                      sender: email,
                      senderusername: username,
                      senderpic: profilepic,
                      receiver: userclicked,
                      receiverusername: receiverusername,
                      receiverpic: receiverpic,
                      time: time,
                      opened: false,
                    };

                    socket.emit("newmsg", jats);
                    stata.current = true;
                    handleSubmit(jats);
                    setMessage("");
                  }
                }}
              >
                <SendOutlinedIcon
                  style={{
                    transform: "rotate(-45deg)",
                    color: "whitesmoke",
                    alignSelf: "baseline",
                    display: "inline-flex",
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="pcv">
        <div className="mscont1">
          <h4>CHATS</h4>
          {chatfiltered.map((chat) => {
            return (
              <div className="mscont111">
                <div className="mscont11">
                  <button
                    style={{
                      alignItems: "center",
                      display: "inline-flex",
                      borderWidth: "0",
                      backgroundColor: "white",
                      paddingBottom: "10%",
                      width: "100%",
                    }}
                    onClick={() => {
                      setUserclicked("");
                      setReceiverpic(chat.userpic);
                      setReceiverusername(chat.username);
                      if (chat.email) {
                        handleClick(chat.email);
                      }
                    }}
                  >
                    <img
                      src={chat.userpic}
                      alt="profilepic"
                      width="40px"
                      height="40px"
                      style={{ borderRadius: "20px", objectFit: "contain" }}
                    ></img>
                    <div className="mscont12">
                      <h5>{chat.username}</h5>
                      <h6>{chat.message}</h6>
                    </div>
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mcont">
          <a
            onClick={() => {
              const yuy = {
                email: email,
                profession: profession,
                username: username,
                profilepic: profilepic,
                about: about,
                receiveremail: userclicked,
                receiverpic: receiverpic,
                receiverusername: receiverusername,
              };
              nav("/gprofile", { state: yuy });
            }}
          >
            <div className="mcont1">
              <img
                src={receiverpic}
                height="40px"
                width="40px"
                style={{
                  borderRadius: "50%",
                  display: "inline-flex",
                  margin: "1%",
                  objectFit: "contain",
                }}
              ></img>
              <h5 style={{ marginLeft: "1%", fontSize: "small" }}>
                {receiverusername}
              </h5>
            </div>
          </a>
          <div ref={dummy} className="mcont2">
            {allchats.map((chat, index) => {
              return (
                <div className="mcont21">
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h4
                      style={{
                        padding: "4px 4%",
                        backgroundColor:
                          chat.sender === email ? "#1C05B3" : "purple",
                        alignSelf: chat.sender === email ? "end" : "start",
                        borderRadius: "4px",
                        color: "whitesmoke",
                        fontWeight: "400",
                        marginBlockStart: "0",
                        margin: "1% 2%",
                      }}
                    >
                      {chat.message}
                    </h4>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mcont3">
            <input
              type="text"
              id="message"
              name="message"
              onKeyDown={(event) => {
                handleKeyPress(event);
              }}
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            ></input>
            <a
              id="myButton"
              onClick={() => {
                if (message !== "") {
                  setAllchats([
                    ...allchats,
                    {
                      email: email,
                      message: message,
                      sender: email,
                      senderpic: profilepic,
                      senderusername: username,
                      receiver: userclicked,
                      receiverusername: receiverusername,
                      receiverpic: receiverpic,
                      time: time,
                      opened: false,
                    },
                  ]);

                  const jats = {
                    email: email,
                    message: message,
                    sender: email,
                    senderusername: username,
                    senderpic: profilepic,
                    receiver: userclicked,
                    receiverusername: receiverusername,
                    receiverpic: receiverpic,
                    time: time,
                    opened: false,
                  };

                  socket.emit("newmsg", jats);
                  stata.current = true;
                  handleSubmit(jats);
                  setMessage("");
                }
              }}
            >
              <SendOutlinedIcon
                style={{
                  transform: "rotate(-45deg)",
                  color: "whitesmoke",
                  alignSelf: "baseline",
                  display: "inline-flex",
                }}
              />
            </a>
          </div>
        </div>
      </div>

      <div className="prmcont8">
        <img
          src={profpic}
          height="4%"
          width="6%"
          style={{ borderRadius: "4px", display: "inline-flex" }}
        ></img>

        <div className="prmcont81">
          <h5>About Us</h5>
          <h5>Community Guidlines</h5>
        </div>

        <div className="prmcont81"></div>

        <div className="prmcont81">
          <h5>Privacy & Terms</h5>
          <h5>Help Center</h5>
        </div>

        <div className="prmcont81">
          <h5>Language</h5>
          <h5>English</h5>
        </div>
      </div>
    </div>
  );
}
export default Messages;
