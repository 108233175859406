import React, { useState } from "react";
import axios from "axios";
import "../views/login.css";
import PersonIcon from "@mui/icons-material/Person";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import { Link, redirect, useNavigate } from "react-router-dom";
import { socket } from "./Pulse";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const nav = useNavigate();

  const firebaseConfig = {
    apiKey: "AIzaSyA9gckdBvs0Q5Nh5WucqQjbz47oIWrqyRY",
    authDomain: "trussnetworks-670ab.firebaseapp.com",
    projectId: "trussnetworks-670ab",
    storageBucket: "trussnetworks-670ab.appspot.com",
    messagingSenderId: "225429067257",
    appId: "1:225429067257:web:7bf452be2833c36b2e9928",
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  function authenticate() {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        socket.emit("join", email);

        nav("/pulse", { state: email });
      })
      .catch((error) => {
        if (error.code == "auth/invalid-email") {
          alert("Invalid email");
        }
        if (error.code == "auth/weak-password") {
          alert("Password too weak");
        }
      });
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      document.getElementById("myButton").click();
    }
  }
  return (
    <div className="lcont">
      <div className="lcont1">
        <h1>Truss Networks</h1>
        <h3>Welcome to your</h3>
        <h3>professional community</h3>
      </div>
      <div className="lcont2">
        <img src="./tlogo.png" alt="picha" height="40px" width="50px"></img>
        <h2>Hello Again! </h2>
        <h4>Welcome Back</h4>

        <div className="lcont21">
          <MailOutlineIcon
            style={{ marginBottom: "2%", color: "grey" }}
            fontSize="small"
          />
          <input
            id="email"
            name="email"
            type="email"
            onKeyDown={(event) => {
              handleKeyPress(event);
            }}
            value={email}
            placeholder="Email Address"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></input>
        </div>
        <div className="lcont21">
          <LockIcon
            style={{ marginBottom: "2%", color: "grey" }}
            fontSize="small"
          />
          <input
            id="password"
            name="password"
            type="password"
            onKeyDown={(event) => {
              handleKeyPress(event);
            }}
            value={password}
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          ></input>
        </div>
        <button
          id="myButton"
          style={{ backgroundColor: "green" }}
          onClick={() => {
            authenticate();
          }}
          type="submit"
        >
          Login
        </button>
        <label>Don't have an account?</label>
        <button
          style={{ marginTop: "2%" }}
          onClick={() => {
            nav("/signup");
          }}
          type="submit"
        >
          Signup
        </button>

        <h6>Forgot Password</h6>
      </div>
    </div>
  );
}
export default Login;
