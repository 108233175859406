import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "../views/upload.css";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { socket } from "./Pulse";
import CloseIcon from "@mui/icons-material/Close";

function Upload(props) {
  const inputref = useRef();
  if (props.vocus === true) {
    inputref.current.focus();
  }
  const cloudinaryRef = useRef();
  const email = props.email;
  const profession = props.profession;
  const username = props.username;
  const profilepic = props.profilepic;
  const [height, setHeight] = useState("50px");
  const widgetRef = useRef();
  const [post, setPost] = useState(null);
  const [message, setMessage] = useState("");
  const [bost, setBost] = useState(null);
  const [upload, setUpload] = useState();
  const [thumbnail, setThumbnail] = useState();
  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "sabai",
        uploadPreset: "aiea32d9",
      },
      function (error, result) {
        if (result.event === "success") {
          setThumbnail(result.info.thumbnail_url);

          setUpload(result.info.original_filename + "." + result.info.format);
          setBost(result.info.secure_url);
          setPost({
            profpic: profilepic,
            username: username,
            work: profession,
            post: message,
            upload: result.info.secure_url,

            likes: [],
            messages: [],
            email: email,
          });
        }
      }
    );
  }, [message]);

  function ubload() {
    if (post !== null || message !== "") {
      const yu = {
        profpic: profilepic,
        username: username,
        work: profession,
        post: message,
        upload: post && post.upload,
        likes: [],
        messages: [],
        email: email,
      };
      socket.emit("newpulse", yu);
      axios
        .post(
          "https://server-101-jcvh.onrender.com/picupload",
          { yu },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          setMessage("");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <div className="ucont">
      <div className="ucont1">
        <h4>NEW POST</h4>
      </div>

      <div className="ucont11">
        <textarea
          onFocus={() => {
            setHeight("400px");
          }}
          onBlur={() => {
            setHeight("50px");
          }}
          style={{ height: height }}
          ref={inputref}
          placeholder="What's on your mind?"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        ></textarea>
        <div style={{ display: "inline-flex", width: "94%", marginLeft: "2%" }}>
          <div
            style={{ display: post !== null ? "flex" : "none", width: "100%" }}
          >
            <img
              src={thumbnail}
              height="20px"
              width="4%"
              style={{ borderRadius: "2px", display: "inline-flex" }}
            ></img>

            <h5 style={{ marginLeft: "2%", marginBlockStart: "0" }}>
              {upload}
            </h5>
            <a
              style={{ marginLeft: "20%" }}
              onClick={() => {
                setPost(null);
              }}
            >
              <CloseIcon />
            </a>
          </div>
        </div>
        <button
          onClick={() => {
            widgetRef.current.open();
          }}
        >
          <AttachFileIcon style={{ transform: "rotate(45deg)" }} />
        </button>
        <button
          onClick={() => {
            widgetRef.current.open();
          }}
        >
          <ImageOutlinedIcon />
        </button>
        <button
          onClick={() => {
            widgetRef.current.open();
          }}
        >
          <VideocamOutlinedIcon />
        </button>

        <button
          style={{
            backgroundColor: "purple",
            color: "white",
            borderRadius: "4px",
            marginLeft: "2%",
            marginRight: "3%",
            paddingLeft: "2%",
            paddingRight: "2%",
            height: "40px",
          }}
          onClick={() => {
            ubload();
          }}
        >
          Upload
        </button>
      </div>
    </div>
  );
}
export default Upload;
